import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const style = {
    custom_link: {
        padding: '0', 
        textDecoration: 'none'
    }, 
    img: {
        maxHeight: '400px', 
        width: '100%',
        objectFit: 'contain'
    }
}

const Product = (props) => {
    
  return (
        <div className='row'>
            <div className="col-md-6">
                <Link to={'/car-service'} className='col col-md-6' style={style.custom_link}>
                    <div>
                        <div className='div_item'>
                            <h6 className='text-center lh-base'>Car Service</h6>
                            <img style={style.img} src='https://www.freeiconspng.com/uploads/yellow-audi-car-png-hd-19.png' />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-md-6">
                <Link to={'/bike-service'} className='col' style={style.custom_link}>
                    <div>
                        <div className='div_item'>
                            <h6 className='text-center lh-base'>Bike Service</h6>
                            <img style={style.img} src='https://www.freepnglogos.com/uploads/bike-png/black-yamaha-yzf-sport-motorcycle-bike-png-image-pngpix-32.png' />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
  )
}

const mapStateToProps = state=>{
    let settingsConfig = state; 
    return {
      settingsConfig
    }
}
  
const mapsDispatchToProps=dispatch=>{
    return {
        
      }
}

export default connect(mapStateToProps, mapsDispatchToProps)(Product)