import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Select from 'react-select'
import { checkPinCode, createLead, onBookService, setLocationAndAddress, showDateForm } from '../redux/action'
import { Button, ButtonGroup, Checkbox, CircularProgress, Input, TextField } from '@mui/material'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapComponent from '../components/GoogleMapComponent'
import DatePickSlider from '../components/DatePickSlider'
import TimePickSlider from '../components/TimePickSlider'
import PhoneVerify from './PhoneVerify'
import PincodeVerify from './PincodeVerify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from 'rsuite'
import "rsuite/dist/rsuite.css";
import isBefore from 'date-fns/isBefore';
import moment from "moment";
import GreenTick from '../assets/GreenTick.svg'

class BookService extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            step: 1,
            optionsModels: [], 
            inputData: {
                    name: '',
                    contact_no: this.props.PhoneVerify.user.display_name,
                    doorno: '',
                    street: '',
                    localityList: [{ 
                        Block : "Chennai", BranchType: "Head Post Office", Circle:"Tamilnadu",
                        Country:"India", DeliveryStatus:"Delivery", Description: null,
                        District: "Chennai", Division: "Chennai GPO", Name :"Chennai ",
                        Pincode:"600001", Region: "Chennai Region", State:"Tamilnadu"
                    }],
                    locality: '',
                    city: '',
                    state: '',
                    country: '',
                    latitue: '',
                    longitute: '',
                    pincode: 600001,
                    bookingDateandTime: {week: null, day: null},
                    // bookingTime: '',
                    can_avail: false,
                    is_valid: false,
                    is_agreed: false,
                    is_otp_verified: false,
                    is_moving_condition: false,
                    selectedModel: null
            },
            responseData: {},
            isFormSubmitted: false,
            success: false,
        }

    }
    
    onChange=(key, value)=>{
        let dateObj = new moment(value);
        var data = {...this.state.inputData}
            data[key]=value
        this.setState({
            inputData: data,    
        })
        
    }
   
    componentDidMount(){
        var vehicle  = window.location.pathname.split('/')[1];
        var optionsData = []
        if(vehicle === 'bike-service') {
            optionsData = this.props.settingsConfig.bikeModels
        } else if(vehicle === 'car-service') {
            optionsData = this.props.settingsConfig.carModels
        }
        this.setState({
            optionsModels: optionsData,
        })
    }

    updateState=(key)=>{
        if(key === 'number'){
            let contacts = {...this.state.inputData}
            if(this.props.PhoneVerify.valid){
                contacts.contact_no = this.props.PhoneVerify.user.display_name
                contacts.is_otp_verified = this.props.PhoneVerify.valid
                
                this.setState({
                    inputData: contacts
                })
            } else {
                contacts.is_otp_verified = false
                contacts.contact_no = ''
                this.setState({
                    inputData: contacts
                })
            }
        } else {
                let {places} = {...this.props.PincodeVerify}
                var setPlaces = {...this.state.inputData}
            if(this.props.PincodeVerify.can_avail){
                setPlaces.country = places[0].Country
                setPlaces.city = places[0].District 
                setPlaces.state = places[0].State
                setPlaces.can_avail = true
                var temp = [];
                places.map((item)=>(
                    temp.push({value: item.Name, label: item.Name})
                ))
                setPlaces.locality = places[0].Name; 
                setPlaces.localityList = temp; 
                this.setState({
                    inputData: setPlaces,
                })
            } else {
                setPlaces.can_avail = false
                this.setState({
                    inputData: setPlaces
                })
            }
            
        }

    }

    

    onSubmit=async(move, step)=>{
        if(move === 'forward') {
            this.setState({
                step: this.state.step >= 2 ? 2 : this.state.step+1
            })
        } else if (move === 'back') {
            this.setState({
                step: this.state.step <= 1 ? 1 : this.state.step-1
            })
        }
        if(step == 1){
            await this.props.createLead({
                    pincode: this.props.PincodeVerify.pincode, 
                    mobile: this.state.inputData.contact_no, 
                    name: this.state.inputData.name, 
                    service_id: JSON.parse(localStorage.getItem('serviceSelected')).id,
                    vehicle: this.state.inputData.selectedModel.value
                })
            
            this.setState({
                responseData: this.props.CreateLeadState,
            })

        } else if(step ==2 ){
            await this.props.onBookService(this.props.CreateLeadState.id, this.props.LocalityDetails, this.state.inputData.selectedModel)

            this.setState({
                isFormSubmitted: true
            })
        }   
    }

    onPickDate=(key, i, j)=>{
        let date = {...this.state.inputData}
        date.bookingDate = {week: i, day: j}
        this.setState({
            inputData: date
        })
    }

    onPickTime=(value)=>{
        let time = {...this.state.inputData}
        time.bookingTime  = value; 
        this.setState({
            inputData: time
        })
    }

    render(){
        const renderElement = (status) => {
            switch (status) {
              case Status.LOADING:
                return <div className='spinner'><CircularProgress /></div>
              case Status.FAILURE:
                return <div>Error in Loading</div>;
              case Status.SUCCESS:
                return <GoogleMapComponent date={this.state.inputData.bookingDateandTime} />;
            }
        };
        // Moto02 13-10-2022
        const today = new Date();
        today.setDate(today.getDate() - 1);
        //End

        let {id} = {...this.props.CreateLeadState}
        return (
            this.state.isFormSubmitted ? 
            <div className='submittedForm d-flex align-items-center justify-content-center flex-column'>
                <img src={GreenTick} className="green-tick"/>
                <h3 className='mt-3'>Thank you</h3>
                <h5 className='my-3'>Your request has been booked successfully!</h5>
                <h6>One of our executive will contact you within 24 hours!</h6>
            </div>
            :
            <form className='book-service' onSubmit={(e)=>this.onSubmit(e)}>
                <fieldset step="1" className={`showForm ${this.state.step == 1 ? 'active' : ''}`}>
                    <h3 className='fontSize-2'>Book your service</h3>
                    <PincodeVerify updateState={this.updateState}/>
                    {/* {  this.state.success ?
                        <p className={`errorMessage success`}>Awesome! Service available</p>
                        :
                        <p className={`errorMessage error`}>No service or invalid pincode</p>
                    } */}
                    <TextField 
                        disabled={this.props.PincodeVerify.can_avail ? false : true}
                        className={'form-control mb-3'} 
                        placeholder={'Name'} 
                        name={'name'}
                        value={this.state.inputData.name}
                        onChange={(e)=>this.onChange(e.target.name, e.target.value)} 
                        type='text'
                        required={true}
                    />
                    <PhoneVerify  disabled={this.props.PincodeVerify.can_avail ? false : true} updateState={(key)=>this.updateState(key)}/>
                    { this.props.PhoneVerify.valid ??
                        <p style={{textAlign: 'right'}} className='errorMessage success'>Verified</p>
                    }
                    
                    <h3 className='mt-3 fontSize-2'>Model</h3>
                        <div className='mb-3'>
                            <Select name='selectedModel' 
                            options={this.state.optionsModels} 
                            value={this.state.inputData.selectedModel} 
                            onChange={(e)=>this.onChange('selectedModel', e)} 
                            />
                        </div>
                    <div className='agree '>
                        <input type='checkbox'   
                            className='pr-2'
                            value={this.state.is_agreed} 
                            name={'is_agreed'}
                            onChange={(e)=>this.onChange('is_agreed', e.target.checked)}/>
                        <label className='fs-5'>I agree to receive updates from MotoExpert on WhatsApp</label>
                    </div>
                </fieldset>

                <fieldset step="2" 
                className={`showForm mb-5 ${this.state.step == 2 ? 'active' : ''}`}
                >
                    <div className='d-flex gap-2 align-items-center'>
                        <h5 className='fontSize-2 m-0'>Booking ID:</h5>
                        <p className='d-block fs-5' >
                            {id}
                        </p>
                    </div>
                    <hr className='hr'/>
                    
                    <div className='d-flex align-items-center mt-3 gap-2' >
                    <h2 className='fontSize-2'>When do you want the service?</h2>
                        <DatePicker format="yyyy-MM-dd HH:mm:ss"
                                    size="lg" placeholder="Large" 
                                    calendarDefaultDate={new Date()}
                                    disabledDate={date => isBefore(date, today)}
                                    name="bookingDateandTime"
                                    ranges={[
                                      {
                                        label: 'Now',
                                        value: new Date()
                                      }
                                    ]}
                                    
                                    style={{ width: 300, display: 'block', marginBottom: 10 }}  
                                    onChange={(e)=>this.onChange('bookingDateandTime', e)}
                                    />
                    </div>
                    
                    <div className='form-group d-flex gap-2 my-3'>
                        <input type={'checkbox'} 
                            name="is_moving_condition" 
                            value={this.state.inputData.is_moving_condition} className="pr-2"
                            onChange={(e)=>this.onChange('is_moving_condition', e.target.checked)}
                            />
                            
                        <label className='fs-5'>Is the vehicle in moving condtion?</label>
                    </div>

                    <h3 className='fontSize-2'>Fill your Address</h3>
                   
                    <Wrapper apiKey={'AIzaSyCQrEUbn7iODsk1XxA2RnZQVquVDCLEqWk'} render={renderElement}  />
                </fieldset>
                
                <div className="form-group text-center">
                    <ButtonGroup className='w-100 gap-3'>
                        <Button 
                            disabled={this.state.step == 1 ? true : false} 
                            className={`btn-submit w-50 ${this.state.step ==1 ? 'bg-secondary' : ''}`} 
                            onClick={(e)=>this.onSubmit('back')}>Previous
                        </Button>
                        <Button 
                            disabled={this.state.inputData.can_avail && this.state.inputData.is_otp_verified && this.state.inputData.is_agreed ? false : true} 
                            className={`btn-submit w-50 `} 
                            onClick={(e)=>this.onSubmit('forward', this.state.step)}>{this.state.step == 2 ? 'Submit' : 'Next'}
                        </Button>
                    </ButtonGroup>
                    
                </div>
            </form>
          )
        }
    }


const mapStateToProps = state=>{
    let settingsConfig = state; 
    let PhoneVerify = state;
    let PincodeVerify = state; 
    let CreateLeadState = state;
    let LocalityDetails = state; 
    return {
        settingsConfig: state.settingsConfig, 
        PhoneVerify: state.PhoneVerify,
        PincodeVerify: state.PincodeVerify, 
        CreateLeadState: state.CreateLeadState,
        LocalityDetails: state.setLocationAndAddress
    }   
}
  
const mapsDispatchToProps=dispatch=>{
    return {
        showDateForm: ()=>{
            dispatch(showDateForm())
        },
        createLead:(body)=>{
            dispatch(createLead(body))
        },
        setLocationAndAddress:()=>{
            dispatch(setLocationAndAddress())
        },
        onBookService:(id, body, make_id)=>{
            dispatch(onBookService(id, body));
        }
    }
}

export default connect(mapStateToProps, mapsDispatchToProps)(BookService)
