import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, InfoWindow, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import pincode from 'pincode-lat-long'
import propTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxPopover } from '@reach/combobox';
import { setLocationAndAddress } from '../redux/action';
const libraries = ["places"];

// function Search({ panTo }) {
//     const {
//       ready,
//       value,
//       suggestions: { status, data },
//       setValue,
//       clearSuggestions,
//     } = usePlacesAutocomplete({
//       requestOptions: {
//         location: { lat: () => 43.6532, lng: () => -79.3832 },
//         radius: 100 * 1000,
//       },
// });

const GoogleMapComponent=(props)=>{
        var lat_lan_byPinCode = {lat: 13.085630, long:80.234840 }; 
        if(props.settingsConfig.is_valid) {
            lat_lan_byPinCode = pincode.getlatlong(props.settingsConfig.pincode)
        } 
        const [position, setPosition] = useState({ lat: lat_lan_byPinCode.lat, lng: lat_lan_byPinCode.long})
        const mapStyles = {        
            height: "50vh",
            width: "100%"
        };

        const { isLoaded, loadError } = useLoadScript({
            googleMapsApiKey: "AIzaSyCQrEUbn7iODsk1XxA2RnZQVquVDCLEqWk",
            libraries,
          });

        useEffect(() => {
            setPosition({lat: lat_lan_byPinCode.lat, lng: lat_lan_byPinCode.long})
        }, [])
       
        const MyMarker=()=>{
            return (
                <Marker position={position} />
            )
        }
        
        const mapRef = React.useRef();

        const panTo = React.useCallback(({ lat, lng }) => {
            mapRef.current.panTo({ lat, lng });
            mapRef.current.setZoom(14);
        }, []);

        return (
            <>
            <Locate panTo={panTo} />
            <Search panTo={panTo} date={props.date} />
            <GoogleMap 
                mapContainerStyle={mapStyles} 
                zoom={13}
                key="AIzaSyCQrEUbn7iODsk1XxA2RnZQVquVDCLEqWk"
                center={position}
                options={{  zoomControl: true, 
                            mapTypeControl: true, 
                            streetViewControlOptions: true, 
                            mapTypeControlOptions: true,
                            
                        }}
                >
                
                <MyMarker />
            </GoogleMap>
           
            </>
          )
}

function Locate({ panTo }) {
    return (
      <button
        className="locate"
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              panTo({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            () => null
          );
        }}
      >
        <img src="/compass.svg" alt="compass" />
      </button>
    );
  }

function Search({panTo, date}){

    const dispatch=useDispatch();

    const { ready, 
            value, 
            suggestions: {status, data}, 
            setValue, clearSuggestions} = usePlacesAutocomplete({
            requestOptions: {
                location: { lat: () => 43.6532, lng: () => -79.3832 },
                radius: 2 * 10
            }
    })
    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
    
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          dispatch(setLocationAndAddress(results, lat, lng, address, date))
          panTo({ lat, lng });
        } catch (error) {
          
        }
      };

      const handleInput = (e) => {
        setValue(e.target.value);
      };

    return (
        <div className='position-relative'>
        <div className='search'>
            <Combobox onSelect={handleSelect}>
                <ComboboxInput 
                    value={value} 
                    onChange={handleInput}
                    // disabled={!ready}
                    placeholder="Enter an address"
                    />
                
                <ComboboxPopover>
                    {   status === "OK" && 
                        data.map(({id, description})=><ComboboxOption 
                        className='popover_option' key={id} value={description} />)}
                </ComboboxPopover>
                
            </Combobox>
        </div>
        </div>
    )
}
GoogleMapComponent.propTypes = {
    style: propTypes.any,
    onclick: propTypes.func,
    onIdle: propTypes.func, 
    children: propTypes.any,
}

const mapStateToProps = state=>{
    let settingsConfig = state.PincodeVerify; 
    return {
      settingsConfig
    }
}
  
const mapsDispatchToProps=dispatch=>{
    return {
        // showDateForm: ()=>{
        //     dispatch(showDateForm(true))
        // }
    }
}

export default connect(mapStateToProps, mapsDispatchToProps)(GoogleMapComponent)
