import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Route, useLocation } from 'react-router-dom';

class SelectProduct extends React.Component{
constructor(props){
    super(props);
    this.state = {
        data: '',
        selected: '',
        setService: {}
    }
    this.onChange = this.onChange.bind(this)
}

onChange=(value)=>{
    
}
componentDidMount(){
    let path = (window.location.pathname).substring(1);
    let temp = []; 
    let type = '';
    if(path === 'car-service'){
        temp = this.props.settingsConfig.carService;
        type = 'Car Service'
    } else if(path === 'bike-service') {
        temp = this.props.settingsConfig.bikeService;
        type = 'Bike Service'
    }
    this.setState({
        data: temp,
        selected: type,
    })
}

    render(){
        const style = {
            Wrapper: {
                cursor: 'pointer',
                minWidth: '11.25rem',
                padding: '0px',
                textDecoration: 'none',
                color: '#000'
            }
        }

        const Product=(props)=>{
            const [selectedProduct, setselectedProduct] = useState(0)
            return(
                <div className="row mt-4">
                {props.products.map((item, index)=>
                    <Link 
                        onClick={()=>localStorage.setItem('serviceSelected',JSON.stringify(item))} 
                        to={`/${(window.location.pathname).substring(1)}/${item.slug}`}  
                        key={item.id} className="col-lg-3 col-md-6 rounded" style={style.Wrapper}
                        >
                        <div className='m-3'>
                        <div key={null} className="div_item">
                            <div className="col-md-12 hover">
                                <span>
                                    <div className="row">
                                        <div className="col-md-12 text-end">
                                            <i className="fa fa-lg fa-check-circle margin-top-10" aria-hidden="true" style={{verticalAlign: '-12px'}}></i>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-2">
                                            <center><img src={'https://motoexpert.in/wp-content/uploads/2022/09/paiting-work-1-150x150.jpg'} style={{width: '100%'}}/></center>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-2">
                                            <center>
                                                <h5>{item.name}</h5>
                                            </center>
                                        </div>
                                    </div>
                                </span>
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <center><p>View details</p></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </Link>
                )}
                </div>
            )
        }

        const SearchComponent=(props)=>{
            return(
                <div className="row justify-content-md-center">
                    <div className="col-md-6">
                        <div className="input-group m-b">
                            <span className="input-group-addon">
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </span>
                            <TextField type="text" name="search" value={props.filteredText} onChange={(e)=>props.onChange(e)} placeholder="Search product" className="form-control"/>
                        </div>
                    </div>
                </div>
            )
        }
        
        const ProductHeader=(props)=>{
            return(
                <div className="row">
                    <div className="col-md-8">
                        <h3 className="displaybl">{this.state.selected}</h3>
                        <label className="integration_label">We send your data to any service you like.</label>
                    </div>
                    <div className="col-md-4 text-end">
                        <img className='img_integrate' style={{maxWidth: '100px'}} src='https://app.customerlabs.com/static/Img/Groupintegrate.svg' alt="group" />
                    </div>
                    <SearchComponent filteredText={props.filteredText} onChange={(e)=>props.onChange(e)}/>
                </div>
            )
        }
        
        const ProductList=(props)=>{
            const rows = [];
            props.products.forEach((item)=>{
                if(item.name.indexOf(props.filteredText)===-1) {
                    return
                } 
                rows.push(item)
            })
            
            return(
                <Product products={rows} 
                />
            )
        }
        
        const AllProducts=(props)=>{
            const [value, setvalue] = useState('')
            const onChange=(e)=>{
                setvalue(e.target.value)
            }
        
            return(
                <div className="row wrapper">
                    <div className="ibox">
                        <ProductHeader filteredText={value} onChange={(e)=>onChange(e)}/>
                        <ProductList products={props.products} filteredText={value}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <AllProducts products={this.state.data ? this.state.data : []} 
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state=>{
    let settingsConfig = state.settingsConfig; 
    return {
      settingsConfig
    }
}
  
const mapsDispatchToProps=dispatch=>{
    return {
       
    }
   
}

export default connect(mapStateToProps, mapsDispatchToProps)(SelectProduct)