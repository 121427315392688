import { applyMiddleware, combineReducers, createStore} from 'redux'
import thunk from 'redux-thunk'
import {logger} from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import {    PhoneVerify, settingsConfig, 
            PincodeVerify, CreateLeadState, 
            setLocationAndAddress, onBookService} from './Reducer'
const middlewares = [thunk];
middlewares.push(logger);

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))
const rootReducer = combineReducers({
    PhoneVerify, settingsConfig, 
    PincodeVerify, CreateLeadState, 
    setLocationAndAddress, onBookService
})

const store = createStore(rootReducer, applyMiddleware(...middlewares))

export default store;