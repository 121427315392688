import React from 'react'
import { addDays, addBusinessDays, eachDayOfInterval, eachWeekOfInterval, subDays} from 'date-fns'
import styled from 'styled-components'
import propTypes from 'prop-types'

const Wrapper = styled.div`
    background: #ddd;
    height: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    border: 1px solid #000; 
    border-radius: 10px;
    cursor: pointer;
`
const d = new Date();

const time = [
    {value: '10.00AM', label: '10.00 AM'},
    {value: '10.30AM', label: '10.30 AM'},
    {value: '11.00AM', label: '11.00 AM'},
    {value: '11.30AM', label: '11.30 AM'},
    {value: '12.00PM', label: '12.00 PM'},
    {value: '12.30PM', label: '12.30 PM'},
    {value: '1.00PM', label: '1.00 PM'},
    {value: '1.30PM', label: '1.30 PM'},
    {value: '2.00PM', label: '2.00 PM'},
    {value: '2.30PM', label: '2.30 PM'},
    {value: '3.00PM', label: '3.00 PM'},
    {value: '3.30PM', label: '3.30 PM'},
    {value: '4.00PM', label: '4.00 PM'},
    {value: '4.30PM', label: '4.30 PM'},
    {value: '5.00PM', label: '5.00 PM'},
    {value: '5.30PM', label: '5.30 PM'},
    {value: '6.00PM', label: '6.00 PM'},
    {value: '6.30PM', label: '6.30 PM'},
    {value: '7.00PM', label: '7.00 PM'},
]

const TimePickSlider = (props) => {
  return (
    <div className='row timePicker' style={{flexWrap: 'nowrap', overflow: 'scroll'}}>
        {
            time.map((item, i)=>(
                <div    className={`item ${props.selected == item.value ? 'active': ''}`}  
                        onClick={()=>props.onclick(item.value)} 
                        key={i}>
                        <div className="div_item">
                            <div>{item.label}</div>
                        </div>
                </div>
            ))
        }
    </div>
  )
}

TimePickSlider.propTypes = {
    name: propTypes.string,
    style: propTypes.string,
    className: propTypes.string,
    onclick: propTypes.func,
    disabled: propTypes.bool,
    selected: propTypes.any
}

export default TimePickSlider