import React from 'react'
import { addDays, addBusinessDays, eachDayOfInterval, eachWeekOfInterval, subDays} from 'date-fns'
import styled from 'styled-components'
import propTypes from 'prop-types'

const Wrapper = styled.div`
    background: #ddd;
    height: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    border: 1px solid #000; 
    border-radius: 10px;
    cursor: pointer;
`
const dates = eachWeekOfInterval(
    { 
        start: subDays(new Date(),1), 
        end: addDays(new Date(),0)
    },
    {
        weekStartsOn: 1
    }
).reduce((acc: Date[][], cur)=> {
    const allDays = eachDayOfInterval({
        start: cur,
        end: addDays(cur,  10)
    })
    acc.push(allDays); 
    return acc; 
}, [])

const DatePickSlider = (props) => {
const weekday = ["SUN","MON","TUE","WED","THU","FRI","SAT"];
  return (
    <div className='row datePicker' style={{flexWrap: 'nowrap', overflow: 'scroll'}}>
    { dates.map((week, i)=>{
        return (
            week.map((day, j)=>{
                return (
                    <div    className={`item ${props.selected.week === i && props.selected.day === j ? 'active' : ''}`} 
                            onClick={()=>props.onclick(day, i, j)} 
                            key={j} date={day.getDate()} day={weekday[day.getDay()]}>
                        <div className="div_item">
                            <div>{weekday[day.getDay()]}</div>
                            <div>{day.getDate()}</div>
                        </div>
                    </div>
                )
            })
        )
    })}
    </div>
  )
}

DatePickSlider.propTypes = {
    name: propTypes.string,
    style: propTypes.string,
    className: propTypes.string,
    onclick: propTypes.func, 
    disabled: propTypes.bool,
    selected: propTypes.any
}

export default DatePickSlider