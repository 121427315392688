import { Input } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { checkPinCode } from '../redux/action'

const PincodeVerify = (props) => {
    const pinCheck = useSelector(state=>state.PincodeVerify)
    const [pinCode, setpinCode] = useState('')
    
    const [msg, setmsg] = useState('')
    const [error, setError] = useState(false)
    const dispatch = useDispatch();
    
    const updateState=async(pincode)=>{
        await dispatch(checkPinCode(pincode));
        props.updateState();
        setError(true)
    }
    useEffect(() => {
      if(!pinCheck.is_valid){
        setmsg('Invalid pincode')
      } else if (pinCheck.can_avail){
        setmsg('Awesome! Service available')
      } else {
        setmsg('No service available at your location')
      }
    })
    
    const onChange=async(pincode)=>{
        setpinCode(pincode)
        setError(false)
    }
   
    return (
        <div className='mb-3'> 
            <Input  
                className={'form-control mb-3'} 
                placeholder={'Pincode'} type='number'   
                name={'pincode'}
                value={pinCode} 
                onChange={(e)=>onChange(e.target.value)} 
                onBlur={(e)=>updateState(e.target.value)} 
                required={true}
                />
                {
                    error ? 
                    <span className={`errorMessage ${pinCheck.can_avail ? 'success' : 'error'}`}>
                        {msg}
                    </span> : null
                }
        </div>
    )
}

export default memo(PincodeVerify)