import EnquiryForm from "./container/EnquiryForm";
import Header from "./components/Header";
import { connect } from "react-redux";
import { fetchBikeModels, fetchBikeService, fetchCarModels, fetchCarService } from "./redux/action";
import React, { useEffect } from "react";
import { Routes, Route, Link } from 'react-router-dom'
import styled from "styled-components"; 
import './style.css'
import SelectProduct from "./container/SelectProduct";
import Footer from "./components/Footer";
import ProductFormFill from "./container/ProductFormFill";
import Product from "./container/Product";


class App extends React.Component {

  constructor(props){
    super(props);
      this.state = {
        
      }
  }

  componentWillReceiveProps(){
    
  }

  componentDidMount(){
    this.props.fetchCarService();
    this.props.fetchBikeService();
    this.props.fetchBikeModels();
    this.props.fetchCarModels();
  }
  render(){
    localStorage.setItem('motoConfig', JSON.stringify(this.props))
    const CONTAINER = styled.div`
      height: 100vh; 
      font-family: Poppins, sans-serif';
    `
    const Wrapper = styled.div``
    return (
      <CONTAINER className="App">
        <Header />
        <Wrapper className="container-fluid m-3">
          <Routes>
            <Route exact path="/" element={<Product />} />
            <Route exact path="/car-service/" element={<SelectProduct  />}/>
            <Route exact path="/bike-service/" element={<SelectProduct />}/>
            <Route exact path={"/*"} element={<ProductFormFill />}/>
          </Routes>
        </Wrapper>
        <Footer />
      </CONTAINER>
    );
  }
}

const mapStateToProps = state=>{
  let settingsConfig = state.settingsConfig; 
  return {
    settingsConfig
  }
}

const mapsDispatchToProps=dispatch=>{
  return {
    fetchCarService:()=>{
      dispatch(fetchCarService())
    },

    fetchBikeService:()=>{
      dispatch(fetchBikeService())
    }
    ,
    fetchCarModels:()=>{
      dispatch(fetchCarModels())
    },
    fetchBikeModels:()=>{
      dispatch(fetchBikeModels())
    }
  }
  
}
export default connect(mapStateToProps, mapsDispatchToProps)(App);

