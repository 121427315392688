import axios from "axios"

var username = 'ck_a9fb9d912104787ceef9b836a06360f1657588c4';
var password = 'cs_76e1f93531989b5cc59a526e9a09d5c6719ba38f';
const url_products = 'https://kaderi4.sg-host.com/wp-json/wc/v3/products/'
// http://kaderi4.sg-host.com/wp-json/soichiro/v1/data/models/car
const url_models = 'http://kaderi4.sg-host.com/wp-json/soichiro/v1/data/models/'
const url_pincode = 'http://kaderi4.sg-host.com/wp-json/soichiro/v1/utilities/pincode/verify/'
const generate_OTP = 'http://kaderi4.sg-host.com/wp-json/soichiro/v1/utilities/otp/generate/'
const verify_OTP = 'http://kaderi4.sg-host.com/wp-json/soichiro/v1/utilities/otp/validate/'
const create_Lead = 'http://kaderi4.sg-host.com/wp-json/soichiro/v1/leads/'
const submit_Data = 'http://kaderi4.sg-host.com/wp-json/soichiro/v1/leads/'
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  
}

export const fetchCarService =() => {
  return async dispatch => {
    try {
      const response  = await axios({ 
                                method: 'GET', 
                                url: url_products, 
                                headers: headers, 
                                auth: {username, password},
                                params: { 'category': '7', 'per_page': 100}
                              })
      if(response.status == 200) {
        dispatch({type: 'FETCH_CAR_SERVICE', data: response.data})
      } 
    } catch (error) { 
      console.log(error)
    }
  }
}

export const fetchBikeService = () => {
  return async dispatch => {
    try {
        const response  = await axios({ 
                                  method: 'GET', 
                                  url: url_products, 
                                  headers: headers, 
                                  auth: {username, password},
                                  params: { 'category': '6', 'per_page': 100}
                                })
        if(response.status == 200) {
          dispatch({type: 'FETCH_BIKE_SERVICE', data: response.data})
        } 
      } catch (error) { 
        console.log(error)
      }
    }
}

export const fetchCarModels=()=>{
  return async dispatch=>{
    try {
      const response  = await axios({ 
                                method: 'GET', 
                                url: url_models+'car?refresh', 
                                headers: headers, 
                                auth: {username, password}
                              })
      if(response.status == 200) {
        dispatch({type: 'FETCH_CAR_MODELS', data: response.data})
      } 
    } catch (error) { 
      console.log(error)
    }
  }
}

export const fetchBikeModels=()=>{
 return async dispatch=>{
      try {
        const response  = await axios({ 
                                  method: 'GET', 
                                  url: url_models+'bike?refresh', 
                                  headers: headers, 
                                  auth: {username, password}
                                })
        if(response.status == 200) {
          dispatch({type: 'FETCH_BIKE_MODELS', data: response.data})
        } 
    } catch (error) { 
      console.log(error)
    }
  }
}

export const checkPinCode= (pincode)=>{
  return async dispatch=>{
    try {
        const response  = await axios({ 
                                  method: 'GET', 
                                  url: url_pincode+pincode, 
                                  headers: headers, 
                                  auth: {username, password}
                                })
        if(response.status == 200) {
          dispatch({type: 'CHECK_PIN_CODE', data: response.data})
          if(response.data.can_avail){
            // toast.success('Awesome! Service available in your area...')
          } else if(!response.data.is_valid){
            // toast.error('Invalid pincode')
          } else if(!response.data.can_avail) {
            // toast.error("Service not available")
          }
        }
    } catch (error) { 
      console.log(error)
    }

    try {
      const response = await axios({method: 'GET', 
              url: `https://api.postalpincode.in/pincode/${pincode}`,
            })
            if(response.status == 200) {
              dispatch({type: 'GET_CURRENT_PLACE', data: response.data[0].PostOffice})
            }
    } catch (error) {
      console.log(error)
    }
  }

}

export const generateOTP=(number)=>{
  return async dispatch=>{
       try {
         const response  = await axios({ 
                                   method: 'GET', 
                                   url: generate_OTP+number, 
                                   headers: headers, 
                                   auth: {username, password}
                                 })
                                 
         if(response.status == 200) {
           dispatch({type: 'GENERATE_OTP', data: response.data})
          //  toast.success('OTP Sent! Please verify')
         } 
     } catch (error) { 
      console.log(error)
     }
   }
 }

 export const verifyOTP=(number, otp)=>{
  return async dispatch=>{
       try {
         const response  = await axios({ 
                                   method: 'GET', 
                                   url: verify_OTP+number+'/'+otp, 
                                   headers: headers, 
                                   auth: {username, password}
                                 })
                                 
         if(response.status == 200) {
           dispatch({type: 'VERIFY_OTP', data: response.data})
          //  toast.success('OTP Verified Successfully.')
         } 
     } catch (error) { 
      console.log(error)
     }
   }
 }

 export const createLead=(body)=>{
  return async dispatch=>{
    try {
      const response  = await axios({ 
                                method: 'POST', 
                                url: create_Lead, 
                                headers: headers, 
                                auth: {username, password},
                                data: body
                              })
      if(response.status >= 200 || response.status <=299) {
        dispatch({type: 'CREATE_LEAD', data: response.data})
      } 
  } catch (error) { 
      // toast.error(error)
      console.log(error)
  }
}
 }

 export const showDateForm= (key)=>{
  return dispatch=>{
    dispatch({type: 'SHOW_DATE_FORM', key})
   }
 }

 export const setLocationAndAddress=(results, lat, lng, address, date)=>{
  return dispatch=>{
    dispatch({type: 'SET_LOCATION_ADDRESS', results, lat, lng, address, date})
   }
 }

 export const onBookService =(id, body)=>{
    return async dispatch=>{
      
      try {
        const response  = await axios({ 
                                  method: 'POST', 
                                  url: submit_Data+id, 
                                  headers: headers, 
                                  auth: {username, password},
                                  data: {body}
                                })
        if(response.status >= 200 || response.status <=299) {
          dispatch({type: 'ON_BOOK_SERVICE', data: response.data})
        } 
    } catch (error) { 
      console.log(error)
    }
    }
 }