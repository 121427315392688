import { Button, TextField } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    height: calc(100vh - 59px); 
    width: 100%; 
    
`
const Form = styled.div`
    position: fixed;
    background-color: #fff; 
    max-width: 478px; 
    right: 30px; 
    top: 50%;
    transform: translateY(-50%);
    padding: 30px; 
    box-shadow: 0 -0.25rem 0.875rem 0 rgb(0 0 0 / 15%), 0 0.0625rem 0.625rem 0 rgb(0 0 0 / 12%), 0 0.25rem 0.9375rem 0.3125rem rgb(0 0 0 / 20%); 

    @media (max-width: 468px){
      padding: 15px;
      left: 50%;
      width: 360px;
      top: 50%; 
      transform: translate(-50%, -50%);
    }
`
const EnquiryForm = () => {


  return (
    <Wrapper>
        <Form className='' style={styled.form}>
            <h2>Experience The Best Car Service in Tamilnadu</h2>
            <p>Get instant quotes for your car service</p>
            {/* <SelectOptions /> */}
            <TextField type={"tel"}/>
            <Button>Check prices for free</Button>
            <span text="By continuing, you agree to GoMechanic's">
              <a> Terms & Condition's </a>&<a> Privacy Policy</a>
            </span>
        </Form>
    </Wrapper>
  )
}



export default EnquiryForm