import React from 'react'
import styled from 'styled-components'


const Header = () => {

  return (
    <nav className="navbar navbar-expand-lg bg-dark">
        <div className="container-fluid px-4">
            <div className='logo'>
                <img src='https://storage.googleapis.com/gomechanic_assets/Website/WHITE.png' />
            </div>
            <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end">
                    <li className='d-flex align-items-center'><a className="dropdown-item text-white mx-3" href="#">Spares</a></li>
                    <li className='d-flex align-items-center'><a className="dropdown-item text-white mx-3" href="#">Blog</a></li>
                    <li className='d-flex align-items-center'><a className="dropdown-item text-white mx-3" href="#">More</a></li>
                    <li className='d-flex align-items-center'><button className='mx-3'>Login</button></li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default Header