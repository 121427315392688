const initialState = {
        carService: [],
        bikeService: [], 
        carModels: [],
        bikeModels: [],
        service: 'test',
        error: false, 
        showDateForm: false,
        errorMessage: ''
}

const checkPhoneNumber = {
        valid: false,
        user: {
            id: null,
            registered: "",
            display_name: "",
            roles: []
        }
}
const CheckpinCode = {
        can_avail: false,
        is_valid: false,
        pincode: '',
        places: []
}

const CreateLeads = {
     
}

const setLocation= {  
        pickup_date_time: "",
        pickup_spot: {
            address: "",
            lat: null,
            lng: null,
            zoom: 20,
            place_id: "",
            name: "",
            street_number: "",
            street_name: "",
            city: "",
            state: "",
            state_short: "",
            post_code: "",
            country: "",
            country_short: ""
        }
}
const ServiceBookingDetails= {  
    pickup_date_time: "",
    make_id: {},
    pickup_spot: {
        address: "",
        lat: null,
        lng: null,
        zoom: 20,
        place_id: "",
        name: "",
        street_number: "",
        street_name: "",
        city: "",
        state: "",
        state_short: "",
        post_code: "",
        country: "",
        country_short: ""
    }

}

export const settingsConfig = (state=initialState, action)=> {
    switch(action.type) {
        case "FETCH_CAR_SERVICE":
            return {
                ...state, 
                carService: action.data
            }

        case "FETCH_BIKE_SERVICE":
            return {
                ...state, 
                bikeService: action.data
            }
        case "FETCH_CAR_MODELS": 
            var temp = [{value: 'others', label: 'Any other model'}]
            action.data.map((item)=>(
                temp.push({value: item.id, label: item.full})
            ))
            return {
                ...state, 
                carModels: temp
            }
        case "FETCH_BIKE_MODELS": 
            var temp = [{value: 'others', label: 'Any other model'}]
            action.data.map((item)=>(
                temp.push({value: item.id, label: item.full})
            ))
            return {
                ...state, 
                bikeModels: temp
            }
       
        case "SHOW_DATE_FORM":
                if(action.key){
                    return {
                        ...state,
                        showDateForm: false
                    }    
                } else {
                    return {
                        ...state,
                        showDateForm: true
                    }
                }
        
                
        default: return state;
        
    }
};

export const PhoneVerify=(state=checkPhoneNumber, action)=>{
    switch(action.type){
        case "VERIFY_OTP": 
        
        if(action.data.valid) {
            return {
                ...state, 
                valid: action.data.valid,
                user: {
                    id: action.data.user.id,
                    registered: action.data.user.registered,
                    display_name: action.data.user.display_name,
                    roles: action.data.user.roles
                }
            }
        } else {
            return {
                ...state, 
                valid: false
            }
        }
        default: return state
    }
}

export const PincodeVerify =(state=CheckpinCode, action)=>{
    
    switch(action.type){
    
        case "CHECK_PIN_CODE": 
            return {
                ...state, 
                can_avail: action.data.can_avail,
                is_valid: action.data.is_valid,
                pincode: action.data.pincode,
            } 
        case "GET_CURRENT_PLACE": 
            return {
                ...state,
                places: action.data
            }
        default: return state
    } 
}

export const CreateLeadState=(state=CreateLeads, action)=>{
    switch(action.type){
        case "CREATE_LEAD": 
            return {
               ...action.data
            }
        default: return state
    }
}

export const setLocationAndAddress=(state=setLocation, action)=>{
    switch(action.type){
        case "SET_LOCATION_ADDRESS": 
            return {
                pickup_date_time: action.date,
                pickup_spot: {
                    address: action.address,
                    lat: action.lat,
                    lng: action.lng,
                    zoom: 13,
                    place_id: action.results[0] ? action.results[0].place_id : null,
                    name: action.results[0].address_components[0] ? action.results[0].address_components[0].long_name : null,
                    street_number: null,
                    street_name: action.results[0].address_components[0] ? action.results[0].address_components[0].long_name : null,
                    city: action.results[0].address_components[3] ? action.results[0].address_components[3].long_name : null,
                    state: action.results[0].address_components[5]  ? action.results[0].address_components[5].long_name : null,
                    state_short: action.results[0].address_components[5]  ? action.results[0].address_components[5].short_name : null,
                    post_code: action.results[0].address_components[7] ? action.results[0].address_components[7].long_name : null,
                    country: action.results[0].address_components[6] ? action.results[0].address_components[6].long_name : null,
                    country_short: action.results[0].address_components[6] ? action.results[0].address_components[6].short_name : null
                }
            }
            default: return state
    }
}

export const onBookService=(state=ServiceBookingDetails, action)=>{
    switch(action.type){
        case "SET_LOCATION_ADDRESS": 
            return {
                pickup_date_time: action.date,
                make_id: action.make_id,
                pickup_spot: {
                    address: action.address,
                    lat: action.lat,
                    lng: action.lng,
                    zoom: 13,
                    place_id: action.results[0] ? action.results[0].place_id : null,
                    name: action.results[0].address_components[0] ? action.results[0].address_components[0].long_name : null,
                    street_number: null,
                    street_name: action.results[0].address_components[0] ? action.results[0].address_components[0].long_name : null,
                    city: action.results[0].address_components[3] ? action.results[0].address_components[3].long_name : null,
                    state: action.results[0].address_components[5]  ? action.results[0].address_components[5].long_name : null,
                    state_short: action.results[0].address_components[5]  ? action.results[0].address_components[5].short_name : null,
                    post_code: action.results[0].address_components[7] ? action.results[0].address_components[7].long_name : null,
                    country: action.results[0].address_components[6] ? action.results[0].address_components[6].long_name : null,
                    country_short: action.results[0].address_components[6] ? action.results[0].address_components[6].short_name : null
                }
            }
            default: return state
    }
}