import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BookService from './BookService'
import ProductInfo from './ProductInfo'

const ProductFormFill = (props) => {
  const [service, setService] = useState({})
  return (
    <div className='row m-0' style={{minHeight: '460px'}}>
        <ProductInfo />
        <div className="col-lg-6 p-3">
            <div className='border p-5 m-3'>
                <BookService />
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = state=>{
    let settingsConfig = state; 
    return {
      settingsConfig
    }
}



export default connect(mapStateToProps, null)(ProductFormFill);