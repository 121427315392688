import React, { memo, useEffect, useState } from 'react'
const ProductInfo = () => {
    const [service, setService] = useState({})
    let data = JSON.parse(localStorage.getItem('motoConfig'))
    useEffect(() => {
        let pathArray = window.location.pathname.split('/');
        let type = ''
        let requiredService = window.location.pathname.split('/').pop();
        if(pathArray[1] === 'car-service'){
            type='carService'
        } else if(pathArray[1] === 'bike-service'){
            type='bikeService'
        }
        
        let temp = data.settingsConfig[type].find(obj => obj.slug === requiredService);
        if(temp){
            setService(temp)
        }
    }, [])
  return (
    <div className="col-lg-6 p-3">
        <div className='border p-5 m-3'>
            <h3><b>Service Type: </b>{service.name}</h3>
            <h5>Cost Rs.{service ? service.price : 0.00}/-</h5>
            {
                service.tax_status ? <h6>Taxable: {service ? service.tax_class : 0.00}</h6> : null 
            }
            <h5>Vehicle Details: </h5>
        </div>
    </div>
  )
}

export default memo(ProductInfo)