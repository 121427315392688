import { Button, Input, TextField } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { generateOTP, verifyOTP } from '../redux/action'


const PhoneVerify = (props) => {
    const [phoneNo, setPhoneNo] = useState('')
    const [OTP, setOTP] = useState('')
    const phoneVerify = useSelector((state)=>state.PhoneVerify.valid)
    const [isGenerated, setisGenerated] = useState(false)
    const [isVerified, setIsVerified]  = useState(false); 
    const dispatch = useDispatch();
    const OTPGenerate=async(phone)=>{
        function validatePhoneNumber(phone) {
            var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            if(re.test(phone)){
                setPhoneNo(phone);
                dispatch(generateOTP(phone))
                setisGenerated(true)
            } else {
                setPhoneNo('')
            }
        }
        if(phone != '') {
            validatePhoneNumber(phone)
        }
        
    }

    const OTPVerify=async(phone, OTP)=>{
        await dispatch(verifyOTP(phone, OTP))
        props.updateState("number");
        setIsVerified(true);
    }
   
  return (
    <div className='d-flex gap-3 contact-group'>
            <div className='col-lg-8'>
                <Input 
                    disabled={props.disabled}
                    className={'form-control mb-3'} 
                    placeholder={'Mobile'} 
                    name={'contact_no'}
                    value={phoneNo} 
                    onChange={(e)=>{setPhoneNo(e.target.value); setisGenerated(false)}}  
                    type='tel'
                    required={true}
                    endAdornment={
                        <Button 
                            className="w-50 bg-success text-white position-absolute h-100 end-0" 
                            onClick={()=>OTPGenerate(phoneNo)}
                            >
                            {isGenerated ? 'Resend OTP' : 'Generate OTP'}  
                            </Button>
                    }
                    />
                    <span className='errorMessage success'>{isGenerated ? 'OTP has been sent, please verify' : ''}</span>
            </div>
            <div className='position-relative col-lg-4 col-sm-12'>
                <Input  className='form-control' name='otp' 
                            onChange={(e)=>{setOTP(e.target.value); setIsVerified(false) }} 
                            value={OTP} 
                            placeholder={'Enter OTP'}  
                            type={'number'}
                            endAdornment={
                                <Button 
                                    disabled={!isGenerated} 
                                    className={`w-50 text-white position-absolute h-100 end-0 ${isGenerated ? 'bg-success' : 'bg-secondary'}`} 
                                    onClick={()=>OTPVerify(phoneNo, OTP)}
                                    >Verify OTP
                                </Button>
                            }
                            />
                            {
                                isVerified ? <span className={`errorMessage ${phoneVerify ? 'success' : 'error'}`}>{phoneVerify ? 'OTP verified successfully' : 'Invalid OTP'}</span>  : null
                            }
                            
                        
            </div>
    </div>
  )
}

export default memo(PhoneVerify)