import React from 'react'

<style>
    
</style>
const Footer = () => {
  return (
    <footer id="footer" className="bg-black text-white">
        <div className="container">
            <div className="row pt-lg-10 pt-5 mb-6">
                <div className="mb-4 mb-0-last-child col-lg-4 col-md-6 col-12">
                    <div className="widget widget_siteinfo mb-4">
                        <a href="https://motoexpert.in/" className="site-title h2 me-3 fw-bold mb-0" rel="home">MotoExpert</a> 
                        <div className="mt-4">                    
                            <p className="">MotoExpert India’s #1 Doorstep Bike Service App startup. Book your bike service at home/office anywhere in India.</p>
                            <ul className="list-unstyled d-flex align-items-center fs-4 mt-4 flex-row" itemScope="" itemType="http://www.schema.org/SiteNavigationElement">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6831 nav-item">
                                    <a className="me-2 p-0 fs-4 lh-1 pe-1" itemProp="url" href="#">
                                        <span itemProp="name"><i className="mdi mdi-facebook" aria-hidden="true"></i> 
                                            <span className="sr-only">facebook</span>
                                        </span>
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6832 nav-item">
                                    <a className="me-2 p-0 fs-4 lh-1 pe-1" itemProp="url" href="#">
                                        <span itemProp="name">
                                            <i className="mdi mdi-twitter" aria-hidden="true"></i> 
                                            <span className="sr-only">Twitter</span>
                                        </span>
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6833 nav-item">
                                    <a className="me-2 p-0 fs-4 lh-1 pe-1" itemProp="url" href="#">
                                        <span itemProp="name"><i className="mdi mdi-instagram" aria-hidden="true"></i> 
                                            <span className="sr-only">Instagram</span>
                                        </span>
                                    </a>
                                </li>
                            </ul> 
                        </div>
                    </div>					
                </div>
                
                <div className="footer-widget-2 mb-4 mb-0-last-child offset-lg-1 col-lg-2 col-md-3 col-6">
                    <div id="nav_menu-6" className="widget mb-4 mb-0-last-child widget_nav_menu">
                        <h3 className="widget-title fw-bold mb-3">Car</h3>
                        <div className="menu-footer-menu-2-container">
                            <ul id="menu-footer-menu-2" className="menu list-unstyled" itemScope="" itemType="http://www.schema.org/SiteNavigationElement">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6801">
                                    <a href="#">Volkswagen</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6802">
                                    <a href="#">Nissan</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6803">
                                    <a href="#">Skoda</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6804">
                                    <a href="#">Chevrolet</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6805">
                                    <a href="#">Fiat</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6806">
                                    <a href="#">Mitsubishi</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6824">
                                    <a href="#">Porsche</a>
                                </li>
                            </ul>
                        </div>
                    </div>					
                </div>
                
                <div className="footer-widget-3 mb-4 mb-0-last-child col-lg-2 col-md-3 col-6">
                    <div id="nav_menu-7" className="widget mb-4 mb-0-last-child widget_nav_menu">
                        <h3 className="widget-title fw-bold mb-3">Bike</h3>
                        
                        <div className="menu-footer-menu-3-container">
                            <ul id="menu-footer-menu-3" className="menu list-unstyled" itemScope="" itemType="http://www.schema.org/SiteNavigationElement">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6807">
                                    <a href="#">Honda</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6808">
                                    <a href="#">TVS</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6809">
                                    <a href="#">Hero Honda</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6810">
                                    <a href="#">Bajaj</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6811">
                                    <a href="#">Yamaha</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6812">
                                    <a href="#">Royal Enfield</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6813">
                                    <a href="#">Hero MotoCorp</a>
                                </li>
                            </ul>
                        </div>
                    </div>					
                </div>
                
                <div className="footer-widget-4 mb-4 mb-0-last-child col-lg-3 col-md-12">
                    <div id="text-3" className="widget mb-4 mb-0-last-child widget_text">
                        <h3 className="widget-title fw-bold mb-3">Get in Touch</h3>			
                        
                        <div className="textwidget">
                            <p>339 McDermott Points Hettingerhaven, NV 15283</p>
                            <p className="mb-1">Email: <a href="https://themeforest.net/user/madrasthemes/portfolio">support@geeksui.com</a></p>
                            <p>Phone: <span className="text-dark fw-semi-bold">(000) 123 456 789</span></p>
                        </div>
                    </div>					
                </div>
            </div>
            <div className="site-info row align-items-center g-0 border-top py-2">
                <div className="col-lg-4 col-md-5 col-12 text-center text-md-start">
                    <span className="geeks-copyright">
                        © 2021 Geeks. All Right Reserved.				
                    </span>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer